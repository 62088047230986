<template>
  <b-modal
    id="modal-quiz-result"
    :visible="show"
    title="Kết quả quiz"
    centered
    hide-footer
    size="lg"
    body-class="p-5"
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <div class="wrap-questions">
        <template v-for="(time, idx) in quizTimes">
          <div :key="idx">
            <h6 class="mb-4">
              Lần {{ time.time }}: {{ time.totalCorrectAnswers }}/{{
                time.totalAnswers
              }}
            </h6>
            <div
              v-for="question in time.quizzes"
              :key="question.id"
              class="question"
            >
              <div class="question__header">
                <div>{{ question.name }}</div>
                <div class="text-center">Câu trả lời</div>
              </div>
              <div
                v-for="answer in question.quizAnswers"
                :key="answer.id"
                class="question__content"
              >
                <div :class="{ 'text-success': answer.isCorrect }">
                  {{ answer.name }}
                </div>
                <div class="d-flex justify-content-center">
                  <b-form-radio
                    :id="answer.id"
                    v-if="question.type === 1"
                    :checked="getUserAnswer(answer.id, question)"
                    class="radio-answer"
                    :name="`some-radios-${answer.id}`"
                    :value="true"
                    disabled
                  />

                  <b-form-checkbox
                    v-else
                    :id="answer.id"
                    class="checkbox-answer"
                    disabled
                    :checked="getUserAnswer(answer.id, question)"
                    :value="true"
                    :name="`checkbox-answer-${answer.id}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </b-overlay>
  </b-modal>
</template>
<script>
export default {
  name: 'ModalQuizResult',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      quizTimes: [],
      loading: false,
    };
  },
  computed: {
    patientId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getQuizResult();
  },
  methods: {
    resetModal() {
      this.$emit('reset');
    },
    async getQuizResult() {
      try {
        this.loading = true;
        const { meta, data, error } = await this.$api.get(
          `/UserDashboard/Lesson/Quiz-By-Lesson`,
          {
            params: {
              patientId: this.patientId,
              lessonId: this.id,
            },
          },
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.quizTimes = data;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
    getUserAnswer(id, question) {
      for (let index = 0; index < question.userAnswers.length; index++) {
        const element = question.userAnswers[index];
        if (element === id) return true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap-questions {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.question {
  &__header {
    background: #f3f6f9;
    display: grid;
    grid-template-columns: 5fr 1fr;
    padding: 14px 8px;
    font-weight: bold;
    color: #888c9f;
    align-items: center;
  }

  &__content {
    padding: 14px 8px;
    display: grid;
    grid-template-columns: 5fr 1fr;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;

    &:last-child {
      border-bottom: unset;
    }
  }
}
</style>

<style lang="scss">
.checkbox-answer .custom-control-label::before,
.checkbox-answer .custom-control-label::after {
  left: -1.55rem;
  width: 1.45rem;
  height: 1.45rem;
}
.radio-answer .custom-control-label::before,
.radio-answer .custom-control-label::after {
  width: 1.45rem;
  height: 1.45rem;
  left: -1.55rem;
}

.radio-answer .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #01645a !important;
  background-color: #01645a !important;
}
.checkbox-answer .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #01645a !important;
  background-color: #01645a !important;
}
</style>
